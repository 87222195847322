@font-face {
	font-family: 'Poppins';
	font-display: auto;
	font-weight: 200;
	src: url('../fonts/Poppins-ExtraLight.ttf');
}
@font-face {
	font-family: 'Poppins';
	font-display: auto;
	font-weight: 300;
	src: url('../fonts/Poppins-Light.ttf');
}
@font-face {
	font-family: 'Poppins';
	font-display: auto;
	font-weight: 400;
	src: url('../fonts/Poppins-Regular.ttf');
}
@font-face {
	font-family: 'Poppins';
	font-display: auto;
	font-weight: 500;
	src: url('../fonts/Poppins-Medium.ttf');
}
@font-face {
	font-family: 'Poppins';
	font-display: auto;
	font-weight: 600;
	src: url('../fonts/Poppins-SemiBold.ttf');
}
@font-face {
	font-family: 'Poppins';
	font-display: auto;
	font-weight: 700;
	src: url('../fonts/Poppins-Bold.ttf');
}
@font-face {
	font-family: 'Poppins';
	font-display: auto;
	font-weight: 800;
	src: url('../fonts/Poppins-ExtraBold.ttf');
}
@font-face {
	font-family: 'Poppins';
	font-display: auto;
	font-weight: 900;
	src: url('../fonts/Poppins-Heavy.ttf');
}
@font-face {
	font-family: 'MMC';
	font-display: auto;
	font-weight: 400;
	src: url('../fonts/MMC-Regular.ttf');
}
@font-face {
	font-family: 'MMC';
	font-display: auto;
	font-weight: 500;
	src: url('../fonts/MMC-Medium.ttf');
}
@font-face {
	font-family: 'MMC';
	font-display: auto;
	font-weight: 700;
	src: url('../fonts/MMC.ttf');
}
